<template>
  <div>
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div class="c-d-flex ui-w-full ui-mb-6">
          <c-skeleton-block
            v-if="loading"
            width="9rem"
            class="ui-mt-2"
          />
          <div
            v-else
            class="c-text-h3"
          >
            Privacidade
          </div>
        </div>

        <div
          v-for="area of anonymizedAreas.filter(a => !a.hidden)"
          :key="area.key"
          class="c-d-flex ui-w-full ui-mb-6"
        >
          <div class="ui-w-10 ui-pt-1">
            <c-skeleton-block
              v-if="loading"
              width="100%"
            />
            <c-toggle
              v-else
              :value="!area.anonymous"
              @input="() => updateAnonymizedAreas(area)"
            />
          </div>
          <div class="c-d-flex ui-flex-col ui-flex-1 ui-ml-6">
            <c-skeleton-block
              v-if="loading"
              class="ui-my-1"
              width="min(100%, 10rem)"
              height="1.5rem"
            />
            <div
              v-else
              class="c-text-subtitle"
            >
              {{ area.name }}
            </div>
            <c-skeleton-block
              v-if="loading"
              width="min(100%, 30rem)"
              height="1.25rem"
            />
            <div
              v-else
              class="c-text-b2 c-text-gray-300"
            >
              {{ area.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div
          class="c-text-h3"
        >
          Exclusão dos dados
        </div>
      </div>
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <div class="user-del-paragraph">
          <div class="user-del-description">
            Ao solicitar a exclusão dos dados sua conta será desativada e você perderá o acesso imediatamente. <br>
            Você receberá posteriormente um e-mail com a confirmação da tratativa. <br>
            Tem certeza que deseja excluir seus dados?
          </div>
          <div>
            <c-button
              v-if="!displayJustification"
              cta
              style="margin: 10px 0 0 10px"
              @click="displayJustification = !displayJustification"
            >
              Sim, desejo excluir meus dados
            </c-button>
            <c-button
              v-else
              style="margin: 10px 0 0 10px"
              @click="displayJustification = !displayJustification"
            >
              Cancelar
            </c-button>
          </div>
        </div>
      </div>
      <div
        v-if="displayJustification"
        class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10"
      >
        <div
          class="c-text-h4"
        >
          Qual o motivo da exclusão?
          <textarea
            class="ui-w-full ui-mt-2"
            rows="4"
            cols="50"
            v-model="justification"
          />
          <c-button
            cta
            style="margin-top: 16px"
            @click="handleModal"
          >
            Confirmar a exclusão
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CSkeletonBlock, CToggle, CButton } from '@estrategiahq/coruja-web-ui';
import { UserStoreKeys } from '@/store/modules/user';
import MFAModal from '@/modals/mfa';
import GenericModal from '@/modals/generic';

export default {
  components: {
    CSkeletonBlock,
    CToggle,
    CButton,
  },
  computed: {
    ...mapGetters({
      user: UserStoreKeys.GET_LOGGED_USER,
      loading: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  created () {
    this.loadPrivacyData();
  },
  data() {
    return {
      anonymizedAreas: [
        {
          key: 'ranking_simulados',
          name: 'Ranking',
          description: 'Exibir meu perfil nos rankings de questões e simulados',
          anonymous: false,
        },
        {
          key: 'forum_questoes',
          name: 'Fórum de questões',
          description: 'Exibir meu perfil no fórum de alunos',
          anonymous: false,
          hidden: !this.$isPreview('fase2'),
        },
        {
          key: 'forum_cursos',
          name: 'Fórum de cursos',
          description: 'Exibir meu perfil no fórum de dúvidas',
          anonymous: false,
          hidden: !this.$isPreview('fase2'),
        },
      ],
      displayJustification: false,
      justification: '',
    };
  },
  methods: {
    async loadPrivacyData() {
      await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);

      this.anonymizedAreas.forEach(area => {
        area.anonymous = this.user.anonymizedAreas.some(key => key === area.key);
      });
    },
    async updateAnonymizedAreas (selectedArea) {
      selectedArea.anonymous = !selectedArea.anonymous;

      await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER_PRIVACY, {
        anonymizedAreas: this.anonymizedAreas.filter(a => a.anonymous).map(a => a.key),
      });

      this.loadPrivacyData();
    },
    async handleModalConfirmation(mfaCode) {
      try {
        await this.$services.accounts.deleteAccount(this.justification, mfaCode);
        this.$router.push({ name: 'logout' });
      } catch (e) {
        if (e.response?.data?.error?.tag === 'MFA_REQUIRED') {
          this.$toast.show({
            type: 'error',
            text: 'Código de confirmação inválido.',
            timeout: 0,
          });
          return;
        }
        this.$toast.show({
          type: 'error',
          text: 'Não foi possível excluir sua conta. Por favor, tente novamente mais tarde.',
          timeout: 0,
        });
      }
    },
    async handleModal() {
      try {
        if (!this.justification || this.justification.trim() === '' || this.justification.length < 50) {
          this.$toast.show({
            type: 'error',
            text: 'Por favor, informe o motivo da exclusão, mínimo de 50 caracteres.',
            timeout: 0,
          });
          return;
        }
        await this.$services.accounts.requestDeleteAccount();
        this.$modal.show({
          component: MFAModal,
          props: {
            title: 'Solicitação de exclusão',
            subTitle: 'Foi enviado um e-mail para você com um código de confirmação.',
            type: 'success',
            cancelButton: {
              onClick: () => this.$modal.hide(),
              label: 'Cancelar',
            },
            confirmButton: {
              onClick: (mfaCode) => this.handleModalConfirmation(mfaCode),
              label: 'Confirmar',
            },
            justification: this.justification,
          },
        });
      } catch (e) {
        const modalProps = {
          title: 'Não foi possível enviar o e-mail de confirmação',
          subTitle: 'Por favor, aguarde alguns minutos e tente novamente',
          type: 'error',
          confirmButton: {
            label: 'Fechar',
            onClick: () => this.$modal.hide(),
          },
        };
        this.$modal.show({
          component: GenericModal,
          props: modalProps,
        });
      }
    },
  },
};
</script>
<style>
.user-del-paragraph {
  background-color: white;
  margin-top: 16px;
  padding: 13px;
}
.user-del-description {
  margin-top: 10px;
  margin-left: 10px;
}
textarea {
  border: 1px solid gainsboro;
  border-radius: 4px;
  padding: 6px;
}
</style>
